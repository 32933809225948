<template>
  <!-- nav -->
  <navbar-component :search="true" :menu="false"></navbar-component>

  <div class="flex flex-col gap-4 mx-4 lg:mx-52 xl:mx-96 pt-6">
    <!-- header -->
    <div class="flex flex-col gap-4 justify-center text-center">
      <div class="font-heading text-6xl xs:text-8xl font-bold tracking-widest drop-shadow-xl">HeideKieker</div>
      <div class="text-lg xs:text-xl drop-shadow-xl">Dein digitaler Freizeitbegleiter</div>
    </div>

    <!-- cards -->
    <!--<div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 3xl:grid-cols-6 gap-2 justify-center items-center justify-items-center">-->
    <div
        class="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-5 3xl:grid-cols-6 gap-4 justify-items-center">
      <div v-for="(card, index) of cards" :key="index"
           class="bg-primary hover:bg-secondary cursor-pointer flex flex-col gap-2 rounded-2xl p-2 items-center justify-center w-36 h-32 md:w-44 md:h-48"
           @click="$router.push(card.path)">
        <!-- icon -->
        <div>
          <i :class="card.icon" class="text-[2em] xs:text-[2em] md:text-[3.5em]"></i>
        </div>
        <!-- title -->
        <div class="font-text text-xl xs:text-xl">
          <p class="break-all">{{ card.name }}</p>
        </div>
      </div>
    </div>

    <!-- imprint -->
    <div class="flex flex-row flex-shrink flex-wrap gap-4 items-center justify-items-center justify-center">
      <div class="flex-shrink bg-primary hover:bg-secondary rounded-2xl px-8 py-2 cursor-pointer"
           @click="$router.push('/imprint')">
        Impressum
      </div>

      <div class="flex-shrink bg-primary hover:bg-secondary rounded-2xl px-8 py-2 cursor-pointer"
           @click="$router.push('/privacy')">
        Datenschutz
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import NavbarComponent from "@/components/NavbarComponent.vue";

export default {
  name: 'HomeView',
  components: {NavbarComponent},
  data() {
    return {
      cards: [
        {
          name: 'Karte',
          path: '/map',
          icon: 'fa-solid fa-location-dot'
        },
        /*
        {
          name: 'Attraktionen',
          path: '/attractions',
          icon: 'fa-solid fa-landmark'
        },
         */
        {
          name: 'Entdecken',
          path: '/attractions',
          icon: 'fa-solid fa-compass'
        },
        {
          name: 'Touren',
          path: '/tours',
          icon: 'fa-solid fa-route'
        },
        /*
        {
          name: 'Rundweg',
          path: '/round-trip',
          icon: 'fa-solid fa-person-hiking'
        },
         */
        {
          name: 'Rundgang',
          path: '/round-trip',
          icon: 'fa-solid fa-shoe-prints fa-rotate-270'
        },
        /*
        {
          name: 'Gastronomie',
          path: '/gastronomy',
          icon: 'fa-solid fa-fork-knife'
        },
        */
        {
          name: 'Gastro',
          path: '/gastronomy',
          icon: 'fa-solid fa-fork-knife'
        },
        {
          name: 'Unterkünfte',
          path: '/accommodations',
          icon: 'fa-solid fa-bed'
        }
      ]
    }
  }
}
</script>
