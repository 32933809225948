<template>
  <div class="font-text text-white"
       :class="{'pointer-events-none': showInstallMessage}">
    <router-view/>

    <transition enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
    >
      <div v-if="showInstallMessage" aria-live="assertive"
           class="pointer-events-none fixed inset-0 flex items-end px-4 py-2 sm:p-6 bg-black/40 z-[99] pointer-events-none">
        <div class="flex flex-col items-center space-y-4 sm:items-end mx-auto">
          <div
              class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="p-4">
              <div class="flex flex-col gap-2">
                <div class="ml-3 flex-1 pt-0.5">
                  <!--<img src="/img/icons/android-chrome-512x512.png" class="w-28 m-auto" alt="HeideKieker Logo">-->
                  <img src="/img/csm_Schnucki_mit_Handy_393901f937.png" class="w-28 h-28 object-contain m-auto" alt="HeideKieker Logo">
                  <p class="text-lg font-bold text-secondary font-heading my-4 text-center">
                    <!-- text-gray-900
                    Füge HeideKieker zu deinem Startbildschirm hinzu!
                    -->
                    Hol dir HeideKieker auf dein Smartphone!
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    <strong class="font-bold font-heading">Für Android:</strong> Öffne HeideKieker im Browser, tippe auf das Menüsymbol (drei Punkte) und
                    wähle „Zum Startbildschirm hinzufügen“. <br>
                    <strong class="font-bold font-heading">Für iOS (iPhone/iPad):</strong> Öffne HeideKieker im Safari-Browser, tippe auf das Teilen-Symbol
                    und wähle „Zum Home-Bildschirm“.
                    <!--
                    Um HeideKieker bequem nutzen zu können, fügen die Seite deinem Startbildschirm hinzu.
                    Hier findest du eine Anleitung für IOS und Android.
                    <br>
                    <a href="https://www.heideregion-uelzen.de/loci-installieren" target="_blank" class="underline">Anleitung</a>
                    -->
                  </p>
                </div>

                <button class="bg-primary rounded-md px-4 py-2" @click="hide()">Schließen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>

export default {
  name: "App",
  data() {
    return {
      showInstallMessage: false,
      localStorageKey: 'visited',
      routes: {
        home: {
          background: {
            image: 'https://www.heideregion-uelzen.de/fileadmin/pwa/loci_pwa_background_home.webp',
            alt: 'Naturpark Suedheide Misselhorner Heide © Lüneburger Heide GmbH'
          },
        },
        tours: {
          background: {
            image: 'https://www.heideregion-uelzen.de/fileadmin/pwa/loci_pwa_background_tours.webp',
            alt: 'Naturpark Suedheide Misselhorner Heide © Lüneburger Heide GmbH'
          },
        }
      }
    }
  },
  created() {
    this.showInstallMessage = !localStorage.getItem(this.localStorageKey);
  },
  watch: {
    $route(route) {
      const body = document.getElementsByTagName('body')[0];

      if (this.routes[route.name]?.background) {
        body.style.backgroundImage = `url('${this.routes[route.name]?.background.image}')`;
        body.classList.add('uelzen-pwa-background');
      } else {
        body.style.backgroundImage = null;
      }
    }
  },
  methods: {
    hide() {
      this.showInstallMessage = false;
      localStorage.setItem(this.localStorageKey, 'true');
    }
  }
}
</script>
